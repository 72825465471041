<template>
   <section class="mb-10 why-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs12 sm10 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center mb-6">
                                    <h3 class="zamu-heading transact-heading product-heading solutions-heading">why choose us</h3><br />
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row >
                
                                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" v-for="(section, index) in sectionInfo" :key="index" class="content-holder">

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                            <v-col cols="12" lg="2" md="2" sm="12" xs="12" align="center" justify="center">
                                                <div class="content-number mb-3"> {{ content.number }} </div>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider"></v-divider>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider"></v-divider>
                                            </v-col>
                                            <v-col cols="12" lg="10" md="10" sm="12" xs="12" class="px-2">
                                               <div class="content-title mb-3 mx-2"> {{ content.header }} </div>
                                               <div class="content-text mx-2" v-html="content.text">
                                               </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'WhyChooseUs',
    mixins: [colorMixin, coreMixin],
     computed: {
        sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'yoga.png',
                    imgClass: 'yoga',
                    content: [
                        {
                            number: '01',
                            header : 'Stable API',
                            text: `
                            <p>Zamupay offers a secure and stable API gateway, supporting multiple payment types as well as multiple countries for our customers’ consumption.<p>
                            `
                        },
                    ]
                },
                 {
                    section: 2,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '02',
                            header : 'Secure',
                            text: `
                           <p> Zamupay is a secure, fraud-proof platform; a gateway to multiple markets, backed by a team with over 30 years experience in Fintech and banking.</p>`
                        },
                     
                    ]
                },
                {
                    section: 3,
                    reverse: true,
                    image: 'yoga.png',
                    imgClass: 'yoga',
                    content: [
                             {
                            number: '03',
                            header : 'Fast and Easy',
                            text: `
                            <p> The platform takes pride in achieving and maintaining the industry’s shortest transaction turnaround time of less than 10 seconds and with huge volumes of individual transactions carried out on the platform. Moreover, the Zamupay platform is built with a user-friendly interface for easy reconciliation and tracking of transactions. </p>
                            `
                        },
                    ]
                },
                {
                    section: 4,
                    reverse: false,
                    image: 'pay.png',
                    imgClass: 'pay',
                    content: [
                        {
                            number: '04',
                            header : '24/7 Support',
                            text: `
                             <p> 
                            We have unrivalled and unmatched customer support, with the ability to provide round-the-clock support to the platform and all our customers with short SLAs of less than 5-10 minutes. The dedicated support team will be available to our customers through Slack, Skype, Chat, Email or dedicated call lines.
                            </p>
                            `
                        },
                   
                    ]
                },
                  {
                    section: 5,
                    reverse: false,
                    image: 'pay.png',
                    imgClass: 'pay',
                    content: [
                             {
                            number: '05',
                            header : 'Super Platform',
                            text: `
                            <p>The platform is developed into a “super platform” with support for multiple countries, multiple currencies and different transaction types.</p>
                            `
                        },
                    ]
                },
                
                {
                    section: 6,
                    reverse: true,
                    image: 'credit-card.png',
                    imgClass: 'credit-card',
                    content: [
                        {
                            number: '06',
                            header : 'Fraud-proof platform',
                            text: `
                            <p>The platform proactively monitors for possible fraudulent or high-risk activity, detects illegitimate transactional behavior online and provides alerts and analysis tools for your personnel.</p>
                            `
                        },
                    ]
                },
               
                  {
                    section: 7,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '07',
                            header : 'Reliability and Accessibility',
                            text: `
                            <p>Through optimization of our systems and well-designed processing gateways, we ensure a reliable and highly accessible system to ensure service to all at all times.</p>
                            `
                        },
                    ]
                },
                {
                    section: 8,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '08',
                            header : 'Simplified Integration to Aggregators',
                            text: `
                            <p>Institutions in all industries can easily integrate their applications to the platform for secure, easy and fast processing of transactions. It also provides a web portal for monitoring and tracking their payments.</p>
                            `
                        },
                    ]
                },
                 {
                    section: 9,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '09',
                            header : 'Security',
                            text: `
                            <p>Customers get the feeling that their funds are safe and secure based on the tech-innovations that we have put in place to safeguard their funds in addition to our platform’s impeccable track record.</p>
                            `
                        },
                    ]
                },
                 {
                    section: 10,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-paymen',
                    content: [
                        {
                            number: '10',
                            header : 'Transparency',
                            text: `
                             <p>Through the platform, customers have a full view of their finances, including volumes traded/transacted at a glance, including how their customers prefer to pay them or be paid by the consumer of Zamupay</p>
                            `
                        },
                    ]
                },
                 {
                    section: 11,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '11',
                            header : 'Functionality',
                            text: `
                            <p>As a one-stop shop for our customers, there is no need to use multiple systems to support one's business, hence materially reducing the workload associated with learning how to use and actually using multiple systems.</p>
                            `
                        },
                    ]
                },
            ];
            return data;
        },
     },

}
</script>

<style scoped>
.why-holder {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/rabbitWatermark.png') !important;
    background-position: left bottom  !important;
    background-size: 60%;

}
 .content-number {
     color: #d29f2a;
    font-size: 28px;
    font-weight: 700;
    line-height: 29.55px;
 }
 .content-title {
     color: #43237d;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
 }
 .content-text {
    color: #727272;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
 }
 .content-image-holder {
    position: relative;

 }
</style>

